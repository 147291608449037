<template>
    <div class="roadFront" v-loading.fullscreen.lock="loading">
<!--        <div class="loading" v-if="loading" style="width: 183px;top: 29px;">-->
<!--            <i class="d-center">-->
<!--                <img src="@/assets/mapimages/new/map-loading.gif" alt />-->
<!--            </i>-->
<!--            <span>正在导出，请稍等！！！</span>-->
<!--        </div>-->
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" >
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 165px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="桥梁名称">
                                        <el-input style="width: 165px" size="medium" v-model="qlmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="桥梁编码">
                                        <el-input style="width: 165px" size="medium" v-model="qlbm" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="年份">
                                        <el-date-picker
                                                        format="yyyy"
                                                        value-format="yyyy"
                                                        v-model="year" type="year" placeholder="选择年份" >
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="开工年">
                                        <el-date-picker
                                                format="yyyy"
                                                value-format="yyyy"
                                                v-model="KGN" type="year" placeholder="选择年份" >
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="评定等级">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="审批流程">
                                        <el-cascader size="medium" style="width: 165px" v-model="checkedFlow" :options="flowList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <!--                                    <el-form-item label="填报批次">-->
                                    <!--                                        <el-cascader-->
                                    <!--                                                v-model="treeVal"-->
                                    <!--                                                :options="treeData"-->
                                    <!--                                                :props="defaultParams"-->
                                    <!--                                                @change="changePc"-->
                                    <!--                                                clearable></el-cascader>-->
                                    <!--                                    </el-form-item>-->
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
<!--                                        <el-button type="text" @click="show3 = !show3" icon="el-icon-arrow-down">{{show3 ? '收起' : '展开'}}</el-button>-->

                                    </el-form-item>

                                </el-form>
                            </div>
<!--                            <el-collapse-transition>-->
<!--                                <div  v-show="show3" class="box-content" style="background: #fff">-->
<!--                                    <div class="tj">-->

<!--                                        <div class="total-item total-item-1">-->
<!--                                            <img src="../../assets/icon/tj1.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>项目数量<small>（个）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.count:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                        &lt;!&ndash; <div class="total-item total-item-5">-->
<!--                                            <img src="../../assets/icon/tj6.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>待提交<small>（个）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ysh:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div> &ndash;&gt;-->

<!--                                        <div class="total-item total-item-5">-->
<!--                                            <img src="../../assets/icon/tj6.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>待审核<small>（个）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ytj:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                        <div class="total-item total-item-4">-->
<!--                                            <img src="../../assets/icon/tj7.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>审核通过<small>（个）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ysh:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->


<!--                                        <div class="total-item total-item-2">-->
<!--                                            <img src="../../assets/icon/tj8.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>驳回<small>（个）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.wtg:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="total-item total-item-6">-->
<!--                                            <img src="../../assets/icon/tj1.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>建设规模-公路<small>（公里）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.jsgm:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->


<!--                                        <div class="total-item total-item-7">-->
<!--                                            <img src="../../assets/icon/tj4.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>当年总投资<small>（万元）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.ztz:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->


<!--                                        <div class="total-item total-item-8">-->
<!--                                            <img src="../../assets/icon/tj5.png" alt="">-->
<!--                                            <div class="t">-->
<!--                                                <h5>当年中央车购税<small>（万元）</small></h5>-->
<!--                                                <b class="date-num">{{tjArr.fillStatistics?tjArr.fillStatistics.zytz:0}}</b>-->
<!--                                            </div>-->
<!--                                        </div>-->

<!--                                    </div>-->
<!--                                </div>-->
<!--                            </el-collapse-transition>-->
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">

                                    <el-button @click="exportData(1)" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">填报</el-button>
                                </span>
                                <div style="display: flex">
                                    <h3 class="tit">桥梁列表</h3>
                                </div>
                            </div>

                            <div class="box-content" style="padding-top: 0;">
                                <div>
                                    <div>
                                        <el-table  @selection-change="handleSelectionChange"  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
<!--                                            <el-table-column-->
<!--                                                    type="selection"-->
<!--                                                    width="55">-->
<!--                                            </el-table-column>-->
                                            <el-table-column prop="index" fixed label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                            <el-table-column fixed prop="" align="center" :width="180" label="操作">
                                                <template slot-scope="scope">
                                                    <!--                                            <span class="newicon iconsyes" @click="showMaps(scope.row)"><i class="el-icon-location icons iconsyes"></i>定位</span>-->
                                                    <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
<!--                                                    <span class="newicon iconsyes" @click="deleted1(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>删除</span>-->
                                                    <span class="newicon iconsyes" v-if="scope.row.IsShowAudit" @click="checkPro(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>审核</span>
                                                    <span class="newicon iconsyes" v-if="scope.row.IsShowSave" @click="checksB(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>上报</span>
<!--                                                    <span class="newicon iconsyes" v-if="scope.row.FlowState == 4 && isAdmin" @click="checkPro1(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>驳回</span>-->
                                                    <!-- <el-popconfirm title="是否确认上报?" @confirm="checksB(scope.row)">
                                                        <template #reference>
                                                            <span class="newicon iconsyes" v-if="scope.row.IsShowSave"><i class="el-icon-edit-outline icons iconsyes"></i>上报</span>
                                                        </template>
                                                    </el-popconfirm> -->
                                                </template>
                                            </el-table-column>
                                            <el-table-column fixed prop="XLXZ"  align="center" label="流程状态" :width="150">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.FlowState == 0"><el-tag>待提交</el-tag></div>
                                                    <el-tooltip  :content="scope.row.Remarks" placement="top" v-if="scope.row.FlowState == -1 || scope.row.FlowState == -2 || scope.row.FlowState == -3">
                                                        <div v-if="scope.row.FlowState == -1"><el-tag type="danger">市级驳回 <i class="el-icon-info"></i></el-tag></div>
                                                        <div v-if="scope.row.FlowState == -2"><el-tag type="danger">公路中心驳回 <i class="el-icon-info"></i></el-tag></div>
                                                        <div v-if="scope.row.FlowState == -3"><el-tag type="danger">报部审核驳回 <i class="el-icon-info"></i></el-tag></div>
                                                    </el-tooltip>
                                                    <div v-if="scope.row.FlowState == 1"><el-tag>市级待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 2"><el-tag>公路中心待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 3"><el-tag >报部待审核</el-tag></div>
                                                    <div v-if="scope.row.FlowState == 4" ><el-tag type="success">审核通过</el-tag></div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="SZS"  label="所在地市" :width="100" sortable show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    {{unique(scope.row.SZS)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="SZX" label="所属县（市）" :width="120" sortable show-overflow-tooltip>
                                                <template slot-scope="scope">
                                                    {{unique1(scope.row.SZX)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column fixed prop="QLMC" label="项目名称" align="center" show-overflow-tooltip :width="320"></el-table-column>
                                            <el-table-column  prop="QLBM" label="桥梁编码" align="center" show-overflow-tooltip :width="180"></el-table-column>
                                            <el-table-column prop="QLZH" label="桥梁桩号" align="center" show-overflow-tooltip ></el-table-column>
                                            <el-table-column prop="JSDD" label="建设地点" align="center" show-overflow-tooltip ></el-table-column>
                                            <el-table-column prop="JSXZ" label="建设性质" align="center" show-overflow-tooltip ></el-table-column>
                                            <el-table-column label="建设年限" align="center"  >
                                                <el-table-column prop="KGN"  label="开工年" align="center"  ></el-table-column>
                                                <el-table-column  prop="WGN" label="完工年" align="center"  ></el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="ZTZ" label="总投资" align="center" show-overflow-tooltip ></el-table-column>
                                            <el-table-column prop="JAF" label="建安费" align="center" show-overflow-tooltip ></el-table-column>
                                            <el-table-column label="资金构成" :width="150"  align="center">
                                                <el-table-column prop="ZJGCZYCGS" label="中央车购税资金" width="90" align="right">
                                                    <template slot-scope="scope">
                                                        {{scope.row.ZJGCZYCGS ? scope.row.ZJGCZYCGS.toFixed(2) : ''}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="ZJGCCPYSF" label="成品油税费改革转移支付资金" width="90" align="right">
                                                    <template slot-scope="scope">
                                                        {{scope.row.ZJGCCPYSF ? scope.row.ZJGCCPYSF.toFixed(2) : ''}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="ZJGCDFPT" label="地方配套" width="90" align="right">
                                                    <template slot-scope="scope">
                                                        {{scope.row.ZJGCDFPT ? scope.row.ZJGCDFPT.toFixed(2) : ''}}
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>

                                            <!--                                            <el-table-column prop="QQMC"  align="center" label="对应前期项目名称" show-overflow-tooltip :width="320" ></el-table-column>-->
                                            <!--                                            <el-table-column prop="XLXZ"  align="center" label="路线性质" show-overflow-tooltip  ></el-table-column>-->
                                            <el-table-column label="桥梁(米)" :width="150"  align="center">
                                                <el-table-column prop="JSGMQK" label="全宽" width="90" align="right">
                                                    <template slot-scope="scope">
                                                        {{scope.row.JSGMQK ? scope.row.JSGMQK.toFixed(2) : ''}}
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="JSGMQC" label="全长" width="90" align="right">
                                                    <template slot-scope="scope">
                                                        {{scope.row.JSGMQC ? scope.row.JSGMQC.toFixed(2) : ''}}
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>

                                        </el-table>
                                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" :before-close="closeDrawer2" size="1300px" :title="XMMC"  class="editModal">
                <el-form :disabled="!isLock"  :model="detail"  ref="detailForm"  class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card" @tab-click="changeTabs">
                        <el-tab-pane label="基本信息" name="0">
                            <div style="overflow: auto;height: calc(100vh - 130px);">
                                <div class="col" >
                                    <div class="col-1">
                                        <el-divider>基本信息</el-divider>
                                        <el-form-item label="年度" prop="JHND" :rules="[{ required: true, message:'请选择日期',trigger: 'blur' }]">
                                            <el-date-picker   style="width: 330px"
                                                            format="yyyy"
                                                            value-format="yyyy"
                                                            v-model="detail.JHND" type="year" placeholder="选择年度" >
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item  label="建设性质" prop="JSXZ" :rules="[{ required: true, message:'请选择建设性质',trigger: 'blur' }]">
                                            <el-select placeholder="请选择建设性质" @change="changeJSXZ"  v-model="detail.JSXZ" >
                                                <el-option label="拆除重建" value="拆除重建"></el-option>
                                                <el-option label="维修加固" value="维修加固"></el-option>
                                                <el-option label="新建桥梁" value="新建桥梁"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label='桥梁名称' :rules="[{ required: true, message:'请选择桥梁',trigger: 'blur' }]" v-if="!inputName">
                                            <div class="dwmcWrap">
                                                <el-tooltip  :content="detail.QLMC" placement="top" v-if="detail.QLMC!= ''">
                                                    <div class="dwmc" :class="{dis:!isLock||!isEdit}" @click="openTable">{{detail.QLMC}}</div>
                                                </el-tooltip>
                                                <div v-else class="dwmc" :class="{dis:!isLock||!isEdit}" @click="openTable">{{detail.QLMC}}</div>
                                                <div class="plus" :class="{dis:!isLock||!isEdit}"><i @click="openTable" class="el-icon-circle-plus"></i></div>
                                            </div>
                                        </el-form-item>
                                        <el-form-item v-else label='桥梁名称' prop="QLMC" :rules="[{ required: true, message:'请输入桥梁名称',trigger: 'blur' }]" >
                                            <el-input :disabled="!inputName" placeholder="桥梁名称" v-model="detail.QLMC" ></el-input>
                                        </el-form-item>
                                        <div style="color: #1e68e5">请选择桥梁数据!</div>
                                        <el-form-item  label="桥梁编码" prop="QLBM" :rules="[{ required: true, message:'请输入桥梁编码',trigger: 'blur' }]">
                                            <el-input :disabled="!inputName" placeholder="桥梁编码" v-model="detail.QLBM" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="桥梁桩号" prop="QLZH" :rules="[{ required: true, message:'请输入桥梁桩号',trigger: 'blur' }]">
                                            <el-input :disabled="!inputName" placeholder="桥梁桩号" v-model="detail.QLZH" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="建设地点" prop="JSDD" :rules="[{ required: true, message:'请输入建设地点',trigger: 'blur' }]">
                                            <el-input :disabled="!inputName" placeholder="建设地点" v-model="detail.JSDD" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="评定等级" prop="PDDJ" :rules="[{ required: true, message:'请输入评定等级',trigger: 'blur' }]">
                                            <el-select placeholder="请选评定等级"  v-model="detail.PDDJ">
                                                <el-option label="一类" value="一类"></el-option>
                                                <el-option label="二类" value="二类"></el-option>
                                                <el-option label="三类" value="三类"></el-option>
                                                <el-option label="四类" value="四类"></el-option>
                                                <el-option label="五类" value="五类"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item  label="是否为2020年普通干线危险桥" prop="SFWXQ2020" :rules="[{ required: true, message:'请选择',trigger: 'blur' }]">
                                            <el-select placeholder="是否为2020年普通干线危险桥"  v-model="detail.SFWXQ2020">
                                                <el-option label="是" :value="true"></el-option>
                                                <el-option label="否" :value="false"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-1">

                                        <el-divider>建设信息</el-divider>
                                        <el-form-item  label="建设单位" prop="JSDW" >
                                            <el-input :disabled="!inputName"  v-model="detail.JSDW" ></el-input>
                                        </el-form-item>
                                        <el-form-item label="开工年" prop="KGN" :rules="[{ required: true, message:'请选择日期',trigger: 'blur' }]">
                                            <el-date-picker :disabled="!isEdit"   style="width: 330px"
                                                            format="yyyy"
                                                            value-format="yyyy-MM-dd"
                                                            v-model="detail.KGN" type="year" placeholder="选择日期" >
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="完工年" prop="WGN" :rules="[{ required: true, message:'请选择日期',trigger: 'blur' }]" >
                                            <el-date-picker :disabled="!isEdit"   style="width: 330px"
                                                            format="yyyy"
                                                            value-format="yyyy-MM-dd"
                                                            v-model="detail.WGN" type="year" placeholder="选择日期" >
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="改造类别"   prop="GZLB" :rules="[{ required: true, message:'请选择改造类别',trigger: 'blur' }]">
                                            <el-select placeholder="请选择改造类别"  v-model="detail.GZLB">
                                                <el-option label="大修" value="大修"></el-option>
                                                <el-option label="中修" value="中修"></el-option>
                                                <el-option label="改建" value="改建"></el-option>
                                                <el-option label="重建" value="重建"></el-option>
                                                <el-option label="修复养护" value="修复养护"></el-option>
                                                <el-option label="应急养护" value="应急养护"></el-option>
                                                <el-option label="专项养护" value="专项养护"></el-option>
                                                <el-option label="无" value="无"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label='主要建设内容'>
                                            <el-input autosize rows="4"  placeholder="请输入备注" type="textarea" v-model="detail.ZYJSNR"></el-input>
                                        </el-form-item>

                                    </div>
                                    <div class="col-1">
                                        <el-divider>审批文号</el-divider>
                                        <!-- :rules="[{ required: true, message:'请填写工可或核准批复文号',trigger: 'change' }]" -->
                                        <el-form-item label="审批文号"  prop="QQHZXXX"  >
                                            <el-input class="xxh"  placeholder="单位" v-model="QQHZXXX.hz" ></el-input>
                                            〔
                                            <el-input class="xxh"  type="number"  placeholder="年份" v-model="QQHZXXX.nf" onkeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                                            〕
                                            <el-input  class="xxh"   placeholder="序号" v-model="QQHZXXX.jw" ></el-input>
                                            号
                                        </el-form-item>
                                        <el-form-item label='备注'>
                                            <el-input autosize rows="4"  placeholder="请输入备注" type="textarea" v-model="detail.BZ"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>

                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="建设规模" name="1">
                            <div class="col" style="justify-content: flex-start;height: 580px">
                                <div class="col-1" style="margin-left: 20px">
                                    <el-divider>现状</el-divider>
                                    <el-form-item label="全宽（米）"  prop="XZQK">
                                        <el-input placeholder="全宽（米）" :disabled="!isEdit" @input="changeJstotal($event,'XZQK')" v-model="detail.XZQK"></el-input>
                                    </el-form-item>
                                    <el-form-item label="全长（米）"   prop="XZQC">
                                        <el-input placeholder="全长（米）" :disabled="!isEdit" @input="changeJstotal($event,'XZQC')" v-model="detail.XZQC"></el-input>
                                    </el-form-item>
                                    <el-form-item label="跨径组合"   prop="XZKJZH">
                                        <el-input placeholder="跨径组合" :disabled="!isEdit"   v-model="detail.XZKJZH"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="col-1" style="margin-left: 20px">
                                    <el-divider>建设规模</el-divider>

                                    <el-form-item label="全宽（米）"  prop="JSGMQK">
                                        <el-input placeholder="全宽（米）" :disabled="!isEdit" @input="changeJstotal($event,'JSGMQK')" v-model="detail.JSGMQK"></el-input>
                                    </el-form-item>
                                    <el-form-item label="全长（米）"   prop="JSGMQC">
                                        <el-input placeholder="全长（米）" :disabled="!isEdit" @input="changeJstotal($event,'JSGMQC')" v-model="detail.JSGMQC"></el-input>
                                    </el-form-item>
                                    <el-form-item label="跨径组合"   prop="JSGMKJZH">
                                        <el-input placeholder="跨径组合" :disabled="!isEdit"   v-model="detail.JSGMKJZH"></el-input>
                                    </el-form-item>

                                </div>
                            </div>

                        </el-tab-pane>

                        <el-tab-pane  label="资金计划（万元）" name="2">
                            <div style="overflow: auto;
    height: calc(100vh - 180px);">
                                <table style="text-align:center;margin-top: 20px;width:100%"  class="littleTable">
                                    <!-- <tr>
                                        <td colspan="11" style="background: #F5F7FA">
                                            <div class="cont" style="text-align: center;width: 100%;font-weight: 600;color: #333">计划总投资</div>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >总投资</div>
                                        </td>
                                        <td rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >建安费</div>
                                        </td>
                                        <td colspan="3" >
                                            <div class="label2" >资金构成</div>
                                        </td>

                                        <!-- <td  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2"  >新增生产能力</div>
                                        </td> -->
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="label2"  >中央车购税资金</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >成品油税费改革转移支付资金</div>
                                        </td>
                                        <td>
                                            <div class="label2"  >地方配套</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style="background: #F5F7FA">
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">计划总资金</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium"  :disabled="!lockZj" class="inputcenter" placeholder="总投资"  v-model="detail.ZTZ"></el-input>
                                        </td>
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium"  :disabled="!lockZj " class="inputcenter" placeholder="建安费"  v-model="detail.JAF"></el-input>
                                        </td>
                                        <td class="ty" >
                                            <el-form-item prop="JHZZJZYTZ" >
                                                <el-input  size="medium" type="number" :disabled="lockZj"  class="inputright" v-model="detail.ZJGCZYCGS" placeholder=" 中央车购税资金"></el-input>
                                            </el-form-item>
                                        </td>

                                        <td class="ty">
                                            <el-form-item prop="JHZZJCPYSF" >
                                                <el-input  type="number"  class="inputright" :disabled="lockZj" size="medium" v-model="detail.ZJGCCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="JHZZJDFZC" >
                                                <el-input  type="number"  class="inputright" :disabled="lockZj"  size="medium" v-model="detail.ZJGCDFPT" placeholder="地方配套"></el-input>
                                            </el-form-item>
                                        </td>

                                    </tr>


                                    <tr class="newform" v-if="ljxdtzlist.length > 0">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter"  disabled placeholder="合计"></el-input>
                                        </td>
                                        <td class="ty">
                                            <!-- DNJHGJ -->
                                            <el-input size="medium" class="inputright" :value="ljxdhj.NJHTZHJ||0"  disabled placeholder="合计"></el-input>
                                        </td>
                                        <td class="ty" >
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZZYTZ||0" disabled placeholder="中央车购税资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  :value="ljxdhj.NJHTZCPYSF||0" size="medium" class="inputright" disabled placeholder="成品油税费改革转移支付资金"></el-input>
                                        </td>
                                        <td class="ty">
                                            <el-input  size="medium" class="inputright" :value="ljxdhj.NJHTZDFZC||0" disabled placeholder="地方配套"></el-input>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td colspan="5" style="background: #F5F7FA">
                                            <!--                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">{{nowYear}}年{{detail.JHPC}}计划资金</div>-->
                                            <div style="width: 100%;text-align:left;font-weight: 600;color: #333;padding: 10px 15px;">本次下达资金计划</div>
                                        </td>
                                    </tr>
                                    <tr class="newform">
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty" style="width:122px">
                                            <el-input size="medium" class="inputcenter" placeholder="-" disabled></el-input>
                                        </td>
                                        <td class="ty" >
                                            <el-form-item prop="NJHTZZYTZ" >
                                                <el-input  size="medium" class="inputright" :disabled="lockZj" type="number"   v-model="detail.BCXDZYCGS" placeholder="中央车购税资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZCPYSF" >
                                                <el-input size="medium" class="inputright" :disabled="lockZj"  type="number" v-model="detail.BCXDCPYSF" placeholder="成品油税费改革转移支付资金"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td class="ty">
                                            <el-form-item prop="NJHTZDFZC" >
                                                <el-input  size="medium" class="inputright"  :disabled="lockZj" type="number" v-model="detail.BCXDDFPT" placeholder="地方配套"></el-input>
                                            </el-form-item>
                                        </td>
                                        <!-- <td class="ty">
                                           <el-form-item prop="NJHTZXZSCNL"   style="width:100px">
                                               <el-input  size="medium" class="inputcenter" v-model="detail.NJHTZXZSCNL" placeholder="新增生产能力"></el-input>
                                           </el-form-item>
                                       </td> -->
                                    </tr>
                                </table>

                            </div>

                        </el-tab-pane>


                        <el-tab-pane label="附件" name="3">
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
                                        地市交通运输局建议计划申请文件</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'JHSQWJFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.JHSQWJFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'JHSQWJFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
                                        <!--                                        <span style="color: #f00;" >*</span>-->
                                        施工图设计文件</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'WQJCBGFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.WQJCBGFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'WQJCBGFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
<!--                                        <span style="color: #f00;">*</span>-->
                                        施工图设计文件批复</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'SJPFFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.SJPFFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SJPFFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
<!--                                        <span style="color: #f00;">*</span>-->
                                        地方政府配套资金承诺函</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'CNHFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.CNHFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'CNHFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="margin-bottom:10px">
                                <el-col :span="24" class="flexs-m">
                                    <div class="name">
                                        <!--                                        <span style="color: #f00;">*</span>-->
                                        协议</div>
                                    <div class="value value1">
                                        <div class="name1">
                                            <el-form-item>
                                                <label class="fright">
                                                    <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                    <input accept="application/pdf" :disabled="!isLock" style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple" @change="onSubmit($event,'XYFJ')" />
                                                </label>
                                            </el-form-item>
                                        </div>
                                        <div class="fileWrap">
                                            <div class="file" v-for="(item,index) in detail.XYFJ" :key="index">
                                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">
                                                    <!--                                                <div @click="exportFile(item.url,item.name)">-->
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                </a>
                                                <!--                                                </div>-->
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'XYFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>

                                    </div>
                                </el-col>
                            </el-row>



                        </el-tab-pane>
                    </el-tabs>


                    <div class="footer">
                        <!-- :loading="buttonLoading" -->
                        <el-button type="primary" @click="save" >保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>
            <!--        十四五项目弹窗-->
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <div class="box-content">
                    <el-form :inline="true" class="form-inline formBox">
                        <el-form-item label="行政区划">
                            <el-cascader :key="parseInt(Math.random()*10000)" style="width: 165px" size="medium" v-model="formInline.region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                        </el-form-item>
                        <el-form-item label="桥梁编码">
                            <el-input clearable style="width: 150px" size="medium" v-model="formInline.QLBM" placeholder="桥梁编码"></el-input>
                        </el-form-item>
                        <el-form-item label="桥梁名称">
                            <el-input style="width: 150px" size="medium" v-model="formInline.QLMC" placeholder="桥梁名称"></el-input>
                        </el-form-item>
                        <el-form-item label="路线编码">
                            <el-input clearable style="width: 150px" size="medium" v-model="formInline.LXBM" placeholder="路线编码"></el-input>
                        </el-form-item>
                        <el-form-item label="评定等级">
                            <el-cascader  size="medium" style="width: 165px" v-model="checkedPDDJ" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                            </el-cascader>
                        </el-form-item>
                        <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;searchCompanyList();">查询</el-button>
<!--                        <el-button type="primary" size="medium" plain @click="clearConditionBridge();search();" icon="el-icon-delete">清空</el-button>-->
                        <el-button type="primary" size="medium" @click="confirmCompany">确定</el-button>
                    </el-form>
                </div>
                <template>
                    <div class="box-content">
                        <el-table @selection-change="selectCompany"  ref="multipleTable" :data="companyData" size="small " border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                                                    <el-table-column fixed type="selection" width="55"  :selectable="checkSelectable"/>

                            <el-table-column fixed prop="LXBM" label="路线编码" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column fixed prop="LXMC" label="路线名称" width="130" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QLBM" label="桥梁编码" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QLMC" label="桥梁名称" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="HZQHMC" label="行政区划名称" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="ZXZH" label="中心桩号" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QC" label="桥长" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="KJZC" label="跨径总长" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="DKZDKJ" label="单孔最大跨径" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="KJZH" label="跨径组合" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QMJK" label="桥面净宽" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="KJFL" label="跨径分类" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="NXFL" label="年限分类" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QMPZLX" label="桥面铺装类型" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QTLX" label="桥台类型" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="SBJGXS" label="上部结构形式" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="SBJGCL" label="上部结构材料" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QDLX" label="桥墩类型" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="SJHZ" label="设计荷载" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="KZDJ" label="抗震等级" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="FHBZ" label="防洪标准" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="SFXZ" label="收费性质" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="PDDJ" label="评定等级" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="PDSJ" label="评定时间" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="PDDW" label="评定单位" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="BHWZ" label="病害位置" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="BHMC" label="病害名称" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="JTGZCS" label="交通管制措施" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="JCSJ" label="建成时间" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="GJSJ" label="改建时间" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="DSJDW" label="地市级单位" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="QXJDW" label="区县级单位" width="" sortable show-overflow-tooltip></el-table-column>
                            <el-table-column prop="SFW2020NPTGXWQ" label="是否为2020年普通干线危桥" width="" ></el-table-column>
                        </el-table>
                            <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    </div>
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span v-if="fillStatus">取消后需重新上报历史记录,是否确定退出?</span>
            <span v-else>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
        <!--        审核窗口-->
        <el-dialog
                :visible.sync="showCheck"
                title="审核"
                width="30%"
        >
            <!-- <div class="cont" style="text-align: left">实际下达资金(万元):</div> -->
            <!-- <el-input
                     type="number"
                    placeholder="请输入内容"
                    v-model="xdje">
            </el-input> -->
            <div class="cont" style="text-align: left">备注:</div>
            <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="remarks">
            </el-input>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkProject(false)">拒绝</el-button>
        <el-button type="primary" @click="checkProject(true)">通过</el-button>
      </span>
            </template>
        </el-dialog>


        <el-dialog
                title="提示"
                :visible.sync="dialogVisible123"
                width="30%">
            <span>是否确认上报项目？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible123 = false">取 消</el-button>
                <el-button type="primary" @click="checksBs();dialogVisible123 = false">确 定</el-button>
            </span>
        </el-dialog>

        <div class="editWrap">
            <!--            建设单位列表弹窗-->
            <el-drawer :visible.sync="frCompany" :title="'建设单位列表'" size="1200px">
                <el-form :inline="true" :model="frForm" class="demo-form-inline" style="margin-top: 42px">
                    <div class="search">
                        <el-form-item label="" prop="dwmc"  :rules="[
                                    { required: false, message:'请输入单位名称', trigger: 'blur' },
                                ]">
                            <el-input v-model="frForm.dwmc"  placeholder="请输入单位名称"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input v-model="frForm.xmzrr" placeholder="请输入项目负责人"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <el-input v-model="frForm.xmlxr" placeholder="请输入项目联系人"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="getFRDW">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="conformXmlxr">确定</el-button>
                        </el-form-item>
                    </div>
                </el-form>
                <template>
                    <el-table
                            :row-class-name="tableRowClassName"
                            max-height="550"
                            border
                            ref="multipleTable1"
                            :data="frCompanyLists"
                            style="width: 100%"
                            @selection-change="selectCompany1"
                    >
<!--                        <el-table-column type="selection" width="55"  :selectable="checkSelectable1"/>-->
                        <el-table-column  :filter-multiple="false"
                                          fixed prop="DWMC" label="单位名称"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMZRR" label="项目负责人"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed width="150px" prop="XMZRRLXDH" label="负责人联系电话"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXR" label="项目联系人"   show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXRLXDH" label="联系人电话"  show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column fixed label="操作"  show-overflow-tooltip >
                            <template slot-scope="scope">
                                <el-button type="text"  size="small" @click="edit(scope.row)">编辑</el-button>
                                <el-popconfirm  title="是否删除此条信息?"  @confirm="deletedCompany(scope.row)">
                                    <template #reference >
                                        <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f">删除</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column> -->
                    </el-table>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
    </div>

</template>

<script>
    import store from "../../store";

    export default {
        name: "bridge",
        data(){
            return{
                year: '2024',
                KGN: '',
                inputName:false,
                FlowState:null,
                isglzx: false,
                qlmc:null,
                qlbm:null,
                show3:false,

                QQSGTXXX:{
                    hz:"",
                    nf:"",
                    jw:""
                },
                QQHZXXX:{
                    hz:"",
                    nf:"",
                    jw:""
                },
                dialogVisible123:false,
                dialogVisibleid:"",
                shsbloading:true,
                tjcount:0,
                buttonLoading:true,
                expParamsCity:[],
                XMFRDW:'',
                XMFRDWID:'',
                activeRoad: '高速公路',
                xdje:'',
                frCompanyList:[{WCTZHJ: '', WCZYCGS: '',}],
                remarks:'',
                showCheck: false,
                isLock: true,
                isEdit:true,
                assign: false,
                lockZj: false,
                formInline:{
                    region: [],
                },
                checkedPDDJ:[],
                activeName:'0',
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                loading:false,
                detail:{},
                showAllTZ:false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                // baseUrl: "http://192.168.0.110:9991/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                checkedJsxz: [], //建设性质
                checkedFlow:[],
                checkedXmlx: [], //项目类型
                checkedXmlxXMLB: [], //项目类型
                region: [""], //已选中行政区划
                /**查询条件 end*/
                checkList: [],
                flowList:[{value:'0',label:'待提交',disabled: false},{value:'1',label:'市级待审核',disabled: false},{value:'4',label:'审核通过',disabled: false},{value:'-1,-2',label:'驳回',disabled: false},{value:'2',label:'公路中心审核',disabled: false},{value:'3',label:'报部待审核',disabled: false}],
                jsxzList: [{value:'拆除重建',label:'拆除重建',disabled: false},{value:'维修加固',label:'维修加固',disabled: false},{value:'新建桥梁',label:'新建桥梁',disabled: false}],
                xmlxList: [{value:'一类',label:'一类',disabled: false},{value:'二类',label:'二类',disabled: false},{value:'三类',label:'三类',disabled: false},{value:'四类',label:'四类',disabled: false},{value:'五类',label:'五类',disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                regions2: [
                    {
                        value: "",
                        label: "黑龙江省",
                        children:[],
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                companyData:[],
                total1:0,
                // nowYear:new Date().getFullYear(),
                nowYear:2024,
                ljxdtzlist:[],
                frCompanyLists:[],
                frCompany:false,
                frdw:'',
                frForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                jhpc:"第二批",
                multipleSelection: [],
                tjArr:{},
                SSWSSWGHXMID:"",
                SSWGHXMMC:"",
                QQID:"",
                QQMC:"",
                bcity:{
                    szs:"",
                    szx:"",
                },
                ssxzc:true,
                props: { multiple: true },
                PCyear:[2024,'第一批'],
                PCnumber:[],
                treeVal:['2024','第一批'],
                defaultParams: {
                    value: "value", //设置默认值value
                    label: "label", //设置显示的是什么
                    children: "children", //设置子元素数组是啥
                    multiple: false,//多选
                },
                treeData:[{
                    value:'2023',
                    label: '2023年投资建议计划',
                    children:[{
                        label: '第一批',
                        value:'第一批',
                    },
                        {
                            label: '第二批',
                            value:'第二批',
                        },
                        {
                            label: '公路投资计划',
                            value:'公路投资计划',
                        }]
                },
                    {
                        value:'2024',
                        label: '2024年投资建议计划',
                        children:[{
                            label: '第一批',
                            value:'第一批',
                        },
                            {
                                label: '第二批',
                                value:'第二批',
                            }]
                    },
                ],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                fillStatus:false
            }
        },
        mounted() {
            var that = this
            // this.nowYear = new Date().getFullYear()
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height -140;
            }, 100);
            this.ljxdtzlist = [];
            // this.activeRoad = "高速公路";
            this.getTJ(2);
            this.getPc();
            this.changeRoad({name:this.activeRoad});
            this.getRegion();
            this.search();

            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
                this.isglzx = res.data.userName == '230002GS'  ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        activated(){
            console.log('进来了')
            if (localStorage.getItem('fillInfo')){
                console.log('有东西的')
                this.fillStatus = true
                this.addRoad()
                this.detail = JSON.parse(localStorage.getItem('fillInfo'))
                console.log(this.detail)
            }
        },
        computed:{

        },
        methods: {
            changeJSXZ(val){
                console.log(val)
                if(val == '新建桥梁'){
                    this.inputName = true
                }else{
                    this.inputName = false

                }
            },
            changeTabs(val){
                if(this.addStatus){
                    this.isLock = true
                    this.lockZj = true
                    return false
                }else if(this.activeName == '2' && this.isglzx && this.FlowState != 3 && this.FlowState != 4 ){
                    this.lockZj = false
                    this.isLock = true
                    console.log( '身份正确')

                }else if( this.isglzx){
                    console.log( '身份不正确')
                    this.lockZj = true
                    this.isLock = false
                }else{
                    this.lockZj = true

                }

                // if(this.activeName == '2' && this.isglzx && this.FlowState != 3 && this.FlowState != 4 ){
                //         this.showtmd = true //编辑的情况下 公路中心可编辑资金
                //         console.log( '身份正确')
                //
                //     }else{
                //         console.log( '身份不正确')
                //         this.showtmd = false
                //
                //     }
                console.log( this.isLock)
            },
            clearConditionBridge() {
                this.formInline.region = [];
                this.formInline.QLBM = '';
                this.formInline.QLMC = '';
                this.formInline.LXBM = '';
                this.checkedPDDJ = [];
            },

            //调用

            changePc(val){
                console.log(val)
                // var a = []
                // val.map((item,index)=>{
                //     a.push(item[1])
                // })
                // this.PCyear = [...new Set(a)]
                this.PCyear =val
            },
            handleNodeClick(data1, data2, data3) {
                console.log(data1); //传递给 data 属性的数组中该节点所对应的对象
                // console.log(data2);//节点对应的 Node
                // console.log(data3);//节点组件本身
                /*
                getCurrentKey 	获取当前被选中节点的 key，使用此方法必须设置 node-key 属性，若没有节点被选中则返回 null
                */
                console.log(this.$refs.tree.getCurrentKey());
            },
            handleCheckChange(nodeObj, SelectedObj) {
                console.log(nodeObj, SelectedObj)
                // console.log(this.$refs.tree.getCheckedNodes(true,false),'node');
                // console.log(this.$refs.tree.getCheckedKeys(false),'key');
                // console.log(this.$refs.tree.getCurrentKey());
                // console.log(this.$refs.tree.getHalfCheckedKeys());

                // if(checked){
                //     console.log(data)
                // }
            },
            changeXLXZ(index){
                console.log(index)
                // if (index === '普通省道'){
                //     this.ssxzc = true
                // }else{
                //     this.ssxzc = false
                // }
            },
            setDSQX(row){
                if(row.SZS==row.SZX || !row.SZX){
                    return row.SZS
                }
                return row.SZS+'/'+row.SZX
            },
            getPc(){
                this.http.post('/api/Sys_Dictionary/GetVueDictionary',['jhtb_pc']).then(res=>{
                    // console.log(res);
                    if(res.length>0){
                        this.jhpc = res[0].data[0].value
                    }
                })
            },
            getTJ(type){
                this.http.post('/api/Plan_filling/getPageTotal', this.postData()).then(res=>{
                    if(type==2){
                        this.bcity = {
                            szs:res.fillStatistics.szs,
                            szx:res.fillStatistics.szx
                        }
                    }else{
                        res.fillStatistics.count = res.fillStatistics.wtg+res.fillStatistics.ysh+res.fillStatistics.ytj;
                        this.tjArr = res;
                    }
                })
                this.http.post('/api/Plan_filling/GetAreaTotal', this.postDataDs()).then(res=>{
                    setTimeout(() => {
                        let arrResult = res.areaStatistics.reduce((pre,cur) =>{
                            pre[cur.xzmc] = cur in pre?pre[cur.xzmc]+ cur.count :cur.count;
                            return pre;
                        },{});
                        this.regions2[0].children.map(r=>{
                            r.label = r.value+"（"+(arrResult[r.value] !== undefined?arrResult[r.value]:'0')+"）"
                        })
                    }, 500);
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            tableRowClassName(row, index) {
                return this.checkSelectable1(row.row) ? "" : "hidden";
            },
            conformXmlxr(){
                if (this.chekfr.length != 0){

                    this.confirmXMFR =  this.chekfr
                    this.XMFRDW = this.confirmXMFR[0].DWMC
                    this.XMFRDWID = this.confirmXMFR[0].ID
                }else{
                    this.confirmXMFR=[{XMZRR:'',XMZRRLXDH:''}],

                        this.XMFRDW = ''
                    this.XMFRDWID = ''
                }
                this.frCompany = false
            },
            checkSelectable1(row) {
                if(!row.XMLXR){
                    row.XMLXR = ''
                }
                if (this.frForm.dwmc && row.DWMC.indexOf(this.frForm.dwmc) < 0 ||
                    this.frForm.xmzrr && row.XMZRR.indexOf(this.frForm.xmzrr) < 0 ||
                    this.frForm.xmlxr && row.XMLXR.indexOf(this.frForm.xmlxr) < 0 ) {
                    return false;
                } else {
                    return true;
                }
            },
            async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },
            getFRDW(){
                var that =this
                this.frCompanyLists = []
                this.http.post('/api/Plan_buildcompany/GetPageData', {rows:1000}).then(res=>{
                    // console.log(res)
                    res.rows.map((item,index)=>{
                        // if (item.XMLX == 'Road'){
                        this.frCompanyLists.push(item)
                        // }
                    })

                    if(this.addStatus){
                        if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                            this.$nextTick(()=>{
                                this.$refs.multipleTable1.clearSelection()
                            })
                        }

                    }else {
                        if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                            setTimeout(()=> {
                                this.$nextTick(() => {
                                    that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                        if (item.ID == that.XMFRDWID) {
                                            return item
                                        }
                                    }))
                                }, 1000)
                            })
                        }
                    }
                })
                // console.log(this.frCompanyLists)
            },
            showFr(){
                var that = this
                // if(!this.isAdmin){
                //     this.$message.warning('暂无此权限')
                //     return false
                // }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            changeRoad(tab, event){
                this.currentPage = 1
                this.checkedXmlxXMLB = [tab.name]
                this.search()
            },
            //所在市去重
            unique(arr) {
                return arr;
                // var a = JSON.parse(arr)
                // console.log(a)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[0])
                // })
                // city =  Array.from(new Set(city))
                // return city =  city.toString()
                // // return arr.filter((arr) => !res.has(arr.projectNo) && res.set(arr.projectNo, 1));
            },
            unique1(arr){
                return arr;
                // var a = JSON.parse(arr)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[1])
                // })
                // return city =  city.toString()
            },
            changeAddress(val){
                console.log(val)
            },
            changeAddressCity(val){
                console.log(val);
            },
            checkPro(row){
                this.showCheck = true
                this.id = row.Id
                // console.log(this.id)
            },
            checkPro1(row){
                this.showCheck = true
                this.id = row.Id
                // console.log(this.id)
            },
            checkProject(val){
                if(this.shsbloading == false){
                    return false;
                }

                console.log(val)
                if (val == false && this.remarks == ''){
                    this.$message.error('请输入拒绝原因')
                    return false
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling_bridge/AuditFilling?id='+this.id+'&isPassed='+val+'&remarks='+this.remarks,{id:this.id,remarks:this.remarks,isPassed:val,xdje:this.xdje}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.$message.success(res.message)
                        this.remarks = ''
                        this.showCheck = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                console.log(this.confirmSSW,123);
                console.log(this.detail)
                if (this.chek.length != 0 && this.chek[0] != null){
                    this.detail.LXBM = this.confirmSSW[0].LXBM
                    this.detail.QLZH = this.confirmSSW[0].ZXZH
                    this.detail.QLBM = this.confirmSSW[0].QLBM
                    this.detail.QLMC = this.confirmSSW[0].QLMC
                    this.detail.XZQC = this.confirmSSW[0].QC
                    this.detail.XZQK = this.confirmSSW[0].QMJK
                    this.detail.XZKJZH = this.confirmSSW[0].KJZH
                    this.detail.SZX = this.confirmSSW[0].JSDD
                    this.detail.JSDW = this.confirmSSW[0].JSDW
                    this.detail.PDDJ = this.confirmSSW[0].PDDJ
                    this.detail.JSDD = this.confirmSSW[0].HZQHMC
                    this.detail.SFWXQ2020 = this.confirmSSW[0].SFW2020NPTGXWQ  ? true : false
                    this.assign = false
                }else{
                    this.$message.error('请选择项目')
                }
            },
            getItemByQQID(row){//根据前期ID获取历年计划
                console.log(123123123123);
                if(row.length>0){
                    var id = row[0].ID;
                    if(id){
                        // var postData = {
                        //     page: 1,
                        //     rows: 1000,
                        //     Sort: "XH",
                        //     Order: "desc",
                        //     wheres: JSON.stringify([]),
                        // };
                        // Plan_invest

                        this.http.post('/api/Plan_filling_history/GetItemByQQID?id='+id, {}).then(res=> {
                            if(res.length>0){
                                let lastdata = res[res.length-1]
                                // this.detail.LJWCTZHJ = lastdata.LJWCTZ;//添加累计完成投资
                                // this.detail.LJWCTZZYTZ=lastdata.LJWCZYCGS;
                                this.detail.LJWCTZCPYSF = lastdata.LJWCTZCPYSF
                                this.detail.LJWCTZCZZJ = lastdata.LJWCTZCZZJ
                                this.detail.LJWCTZDFZC = lastdata.LJWCTZDFZC
                                this.detail.LJWCTZHJ = lastdata.LJWCTZHJ
                                this.detail.LJWCTZJSNR = lastdata.LJWCTZJSNR
                                this.detail.LJWCTZQYZC = lastdata.LJWCTZQYZC
                                this.detail.LJWCTZXZSCNL = lastdata.LJWCTZXZSCNL
                                this.detail.LJWCTZYHDK = lastdata.LJWCTZYHDK
                                this.detail.LJWCTZZXZQ = lastdata.LJWCTZZXZQ
                                this.detail.LJWCTZZYTZ = lastdata.LJWCTZZYTZ
                                this.setZjValue(res)
                            }
                            this.ljxdtzlist = this.sortByKey(res,"XH",'asc')
                            console.log(this.ljxdtzlist.length,'this.ljxdtzlist')
                            if(this.ljxdtzlist.length >0){
                                this.showAllTZ = false
                            }else{
                                this.showAllTZ = true
                            }
                        })
                    }
                }
            },
            setZjValue(res){
                var hj = 0;
                var cgs = 0;
                var cz = 0;
                var cpy = 0;
                var zx = 0;
                var yh = 0;
                var qy = 0;
                var df = 0;
                var zc = 0;
                var sb = 0;
                res.map(r=>{
                    hj+=this.moneyToNumFiled(r.NJHTZHJ||0);
                    cgs+=this.moneyToNumFiled(r.NJHTZZYTZ||0);
                    cz+=this.moneyToNumFiled(r.NJHTZCZZJ||0);
                    cpy+=this.moneyToNumFiled(r.NJHTZCPYSF||0);
                    zx+=this.moneyToNumFiled(r.NJHTZZXZQ||0);
                    yh+=this.moneyToNumFiled(r.NJHTZYHDK||0);
                    qy+=this.moneyToNumFiled(r.NJHTZZQYZC||0);
                    df+=this.moneyToNumFiled(r.NJHTZDFZC||0);
                    zc+=this.moneyToNumFiled(r.NJHTZSSXZC||0);
                    sb+=this.moneyToNumFiled(r.NJHTZSJBZZZ||0);
                })

                this.ljxdhj.NJHTZHJ = hj;
                this.ljxdhj.NJHTZZYTZ = cgs;
                this.ljxdhj.NJHTZCZZJ = cz;
                this.ljxdhj.NJHTZCPYSF = cpy;
                this.ljxdhj.NJHTZZXZQ = zx;
                this.ljxdhj.NJHTZSSXZC = zc;
                this.ljxdhj.NJHTZYHDK = yh;
                this.ljxdhj.NJHTZZQYZC = qy;
                this.ljxdhj.NJHTZDFZC = df;
                this.ljxdhj.NJHTZSJBZZJ = sb;
                console.log(this.detail.JHZZJSSXZC,zc,this.detail.NJHTZSSXZC,'嘎嘎嘎嘎')
                this.detail.SYZJHJ = (parseFloat(this.detail.JHZZJHJ||0)-hj-parseFloat(this.detail.NJHTZHJ||0)).toFixed(2);
                this.detail.SYZJZYTZ =  this.setNumDefault(this.detail.JHZZJZYTZ)-cgs-this.setNumDefault(this.detail.NJHTZZYTZ);
                this.detail.SYZJCZZJ = this.setNumDefault(this.detail.JHZZJCZZJ)-cz-this.setNumDefault(this.detail.NJHTZCZZJ);
                this.detail.SYZJCPYSF = this.setNumDefault(this.detail.JHZZJCPYSF)-cpy-this.setNumDefault(this.detail.NJHTZCPYSF);
                this.detail.SYZJZXZQ = this.setNumDefault(this.detail.JHZZJZXZQ)-zx-this.setNumDefault(this.detail.NJHTZZXZQ);
                this.detail.SYZJSSXZC =this.setNumDefault(this.detail.JHZZJSSXZC)-zc-this.setNumDefault(this.detail.NJHTZSSXZC);
                this.detail.SYZJYHDK = this.setNumDefault(this.detail.JHZZJYHDK)-yh-this.setNumDefault(this.detail.NJHTZYHDK);
                this.detail.SYZJZQYZC = this.setNumDefault(this.detail.JHZZJQYZC)-qy-this.setNumDefault(this.detail.NJHTZZQYZC);
                this.detail.SYZJDFZC = (this.setNumDefault(this.detail.JHZZJDFZC)-df-this.setNumDefault(this.detail.NJHTZDFZC)).toFixed(2);
                this.detail.SYZJSJBZZJ = this.setNumDefault(this.detail.SJBZZJ)-sb-this.setNumDefault(this.detail.NJHTZSJBZZJ);

                if(this.detail.JHZZJDFZC == null){
                    this.detail.JHZZJDFZC  =df
                }
            },
            Subtr(arg1,arg2){
                var r1,r2,m,n;
                try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
                try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
                m=Math.pow(10,Math.max(r1,r2));
                n=(r1>=r2)?r1:r2;
                console.log(n)
                return ((arg1*m-arg2*m)/m);
            },
            sortByKey(array,key,type){
                return array.sort(function(a,b){
                    var x = parseFloat(a[key]);
                    var y = parseFloat(b[key]);
                    if(type == 'desc'){
                        return((x>y)?-1:((x<y)?1:0));
                    }else{
                        return((x<y)?-1:((x>y)?1:0));
                    }
                })
            },
            openTable(){
                if(!this.isLock || !this.isEdit){
                    return
                }
                var that = this
                this.assign = true
                this.formInline = {}
                this.currentPage1 = 1
                this.getCompanyList()
            },
            getCompanyList(page){
                var that = this
                this.http.post('/api/Plan_danger_bridge/GetData', this.postData1(page)).then(res=> {
                    this.companyData = res.rows
                    this.total1 = res.total
                    this.$nextTick(() => {
                        that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                                if (item.QLBM == that.detail.QLBM){
                                    return item
                                }
                            }),
                            true);
                    })
                })
            },
            postData1(page){
                var szds = "";
                var ssx = "";
                if(this.formInline.region){

                var reg = JSON.parse(JSON.stringify(this.formInline.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }
                }

                //项目名称
                var query_xmmc = {
                    Name: "QLBM",
                    Value: this.formInline.QLBM,
                    DisplayType: "like",
                };
                var query_QLMC = {
                    Name: "QLMC",
                    Value: this.formInline.QLMC,
                    DisplayType: "like",
                };
                var query_LXBM = {
                    Name: "LXBM",
                    Value: this.formInline.LXBM,
                    DisplayType: "like",
                };
                var query_HZQHMC = {
                    Name: "HZQHMC",
                    Value: ssx,
                    DisplayType: "HZQHMC",
                };
                console.log(this.checkedPDDJ)
                var query_pddj = {
                    Name: "PDDJ",
                    Value: this.checkedPDDJ.length == 0 ? '' : this.checkedPDDJ.join(","),
                    DisplayType: "checkbox",
                };
                if(page){
                    this.currentPage1 = page
                }
                var postData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    Sort: "DSBM",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_pddj,
                        query_xmmc,
                        query_QLMC,
                        query_LXBM,
                        query_HZQHMC

                    ]),
                };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                return postData;
            },
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            closeDrawer2(){

                console.log(this.isLock);
                if(this.isLock == false){
                    this.$refs.detailForm.resetFields()
                    this.showAddModel = false;
                }else{
                    this.showMessage = true
                }
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkQQXXX(rule, value, callback)  {
                console.log(this.QQHZXXX,value,rule)
                var hz = "";
                var nf = "";
                var jw = "";
                var ts = "";
                if(value  == 'QQHZXXX'){
                    hz = this.QQHZXXX.hz
                    nf = this.QQHZXXX.nf
                    jw = this.QQHZXXX.jw
                    ts = "请输入审批文号";
                }

                // console.log(nf);
                var reg = new RegExp("[\u4E00-\u9FA5]+$");
                if(!hz || !nf || !jw){
                    callback(new Error(ts));
                }else if(nf.length != 4){
                    // nf = nf.slice(0,4)
                    callback(new Error('请输入正确的年份'));
                }else if (reg.test(hz) == false) {
                    callback(new Error("单位只能输入中文"));
                }else{
                    console.log(123123123123);
                    callback();
                }

                // if(this.QQHZXXX[0])

            },

            checkNumFs  (rule, value, callback)  {
                if (value < 0) {
                    callback(new Error('不能为负数'));
                }else{
                    callback();
                }
            },
            checkNum  (rule, value, callback)  {
                console.log(value)
                if (!value) {
                    callback(new Error('请输入数字值'));
                }
                // if (value < 0) {
                //     callback(new Error('不能为负数'));
                // }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(){
                console.log(this.tjArr,123);
                var that = this
                this.showAllTZ = true
                this.inputName = false
                this.buttonLoading = false;
                this.addStatus = true
                this.isLock = true
                this.isEdit = true
                this.XMMC = ''
                this.currentPage1 = 1
                this.showAddModel = true
                    this.QQHZXXX={
                        hz:"",
                        nf:"",
                        jw:""
                    },
                    this.detail = {
                        SJPFFJ:[],
                        WQJCBGFJ:[],
                        JHSQWJFJ:[],
                        CNHFJ:[],
                        XYFJ:[],
                    }
                // if(this.detail.XMLB == '普通省道'){
                //     this.ssxzc = true
                // }else{
                //     this.ssxzc = false
                // }
                // if(this.bcity.szs == this.bcity.szx){
                //     this.detail.SZX = "";
                // }
                console.log(this.detail);
            },
            //退出编辑
            closeEdit(){
                localStorage.removeItem('fillInfo')
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {}
            },
            //删除图片
            deleted(index,type){
                console.log(this.detail[type])
                this.detail[type].splice(index,1)
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                 this.detail.JSGMQK == null || this.detail.JSGMQK ==  '' || !reg.test(this.detail.JSGMQK) ? 0 : parseFloat(this.detail.JSGMQK)
                this.detail.JSGMQC == null || this.detail.JSGMQC ==  '' || !reg.test(this.detail.JSGMQC) ? 0 : parseFloat(this.detail.JSGMQC)
                this.detail.XZQK == null || this.detail.XZQK ==  '' || !reg.test(this.detail.XZQK) ? 0 : parseFloat(this.detail.XZQK)
                this.detail.XZQC == null || this.detail.XZQC ==  '' || !reg.test(this.detail.XZQC) ? 0 : parseFloat(this.detail.XZQC)

            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            accDec(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m - arg2 * m) / m;
            },
            //附件处理
            dealFj(data){
                // console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                console.log(data);
                if(data == null){
                    return ''
                }
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                // console.log( pfwjString.join(';'),456)
                return pfwjString.join(';')
            },
            save(){
                if(this.buttonLoading == true){
                    return
                }
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        // params.FillingType = '1'
                        // params.jhnd = 2024
                            params.SJPFFJ = this.dealSaveFj(params.SJPFFJ),
                            params.WQJCBGFJ = this.dealSaveFj(params.WQJCBGFJ)
                            params.JHSQWJFJ = this.dealSaveFj(params.JHSQWJFJ)
                            params.CNHFJ = this.dealSaveFj(params.CNHFJ)
                            params.XYFJ = this.dealSaveFj(params.XYFJ)

                        let regs=new RegExp('号','g')
                        console.log(this.QQHZXXX.hz)
                        if(this.QQHZXXX.hz && this.QQHZXXX.hz.length != 0){
                            params.SPWH = this.QQHZXXX.hz+'〔'+this.QQHZXXX.nf+'〕'+(this.QQHZXXX.jw.replace(regs,""))+'号'
                        }else{
                            params.SPWH = ''
                        }
                        if(params.KGN){
                            let kgn = params.KGN.split("-");
                            if(kgn.length>1){
                                params.KGN = kgn[0]
                            }
                        }
                        if(params.WGN){
                            let wgn = params.WGN.split("-");
                            if(wgn.length>1){
                                params.WGN = wgn[0]
                            }
                        }

                        // console.log(params)
                        // if(!params.SJPFFJ){
                        //     this.$message.error("请上传施工图设计文件批复文件");
                        //     return
                        // }
                        // if(!params.WQJCBGFJ){
                        //     this.$message.error("请上传施工图设计文件");
                        //     return
                        // }
                        // if(!params.JHSQWJFJ){
                        //     this.$message.error("请上传地市交通运输局建议计划申请文件");
                        //     return
                        // }
                        // if(!params.CNHFJ){
                        //     this.$message.error("请上传地方政府配套资金承诺函");
                        //     return
                        // }

                        this.buttonLoading = true;

                        if(this.addStatus){
                            params.id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_filling_bridge/AddItem',params).then(res=>{
                                this.buttonLoading = false;
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }else{
                            this.buttonLoading = false;
                            this.http.post('/api/Plan_filling_bridge/UpdateItem',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.$message.success(res.message)
                                    this.search()
                                    this.$refs.detailForm.resetFields()
                                    this.addStatus = true
                                }
                            })
                        }
                    }
                });
            },
            checksB(row){
                this.dialogVisibleid = row.Id
                this.dialogVisible123 = true;
            },
            checksBs(){
                if(this.shsbloading == false){
                    return false;
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling_bridge/SaveFilling?id='+this.dialogVisibleid,{}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.showAddModel = false
                        this.$message.success("上报成功")
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            getCaption(obj){
                var index=obj.lastIndexOf(".");
                obj=obj.substring(index+1,obj.length);
                return obj;
            },
            onSubmit(e, type) {
                if (!this.detail[type]){
                    this.detail[type] = []
                }
                console.log( this.detail[type])

                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                let hz =  this.getCaption(file[0].name)
                    if (hz != 'pdf' && hz != 'PDF' ){
                        this.$message.warning('请上传pdf格式文件')
                        this.uploadLoading = false
                        return false
                    }

                let aaa = {
                    SJPFFJ:"施工图设计文件批复",
                    WQJCBGFJ:"施工图设计文件",
                    JHSQWJFJ:"地市交通运输局建议计划申请文件",
                    CNHFJ:"地方政府配套资金承诺函",
                    XYFJ:"协议",
                }
                // console.log(file)
                file.forEach((element) => {
                    form.append("fileInput", element);
                    form.append("fileNames",(this.detail.XMMC?this.detail.XMMC:"")+'-'+aaa[type])
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_filling/upload", form).then((res) => {
                    this.uploadLoading = false;
                    arrs.forEach((v) => {
                        // console.log(v)
                        console.log(this.detail)
                        this.detail[type].push({ url: res.data, name: v });
                    });
                });
                // console.log(type)
            },
            deleted1(row){
                this.http.post('/api/Plan_filling_bridge/DeleteByID?id='+row.Id,{}).then(res=>{
                    this.getList()
                })
            },
            openDetailsEdit(row) {
                this.buttonLoading = false;
                this.activeName = '0'
                this.FlowState = row.FlowState
                // this.isLock = true
                // this.isEdit = true;
                if(row.FlowState >= 3){
                    this.isLock = row.IsShowSave;
                }
                // if(row.BZ == '二批' && row.IsShowSave){
                //     this.isLock = true;
                // }else{
                //     this.isLock = false
                //
                // }
                // console.log(this.isLock,123)
                // this.isEdit = row.SFXJ_XMLB=='是'?false:true;
                // var that = this
                this.showAddModel = true
                this.activeName = '0'
                this.addStatus = false
                this.ljxdtzlist = [];
                this.http.post('/api/Plan_filling_bridge/GetItemById?id='+row.Id,{}).then(res=>{
                    // res.buildAddress = JSON.parse(res.SZS)
                    res.buildAddress = [res.SZS,res.SZX]
                    // this.XMMC = res.QQMC
                    // this.QQID = res.QQID
                    // console.log(res.QQHZ);
                    // console.log(res.QQSGT);

                    let regs=new RegExp('号','g')

                    if(res.SPWH){
                        let HQXXXS = res.SPWH.split('〔');
                        if(HQXXXS.length>1){
                            let HQXXXS2 = HQXXXS[1].split('〕');
                            this.QQHZXXX.hz = HQXXXS[0];
                            this.QQHZXXX.nf = HQXXXS2[0];
                            this.QQHZXXX.jw = HQXXXS2[1].replace(regs,"");
                        }

                    }else{
                        this.QQHZXXX.hz = [];
                        this.QQHZXXX.nf = [];
                        this.QQHZXXX.jw =[];
                    }
                    this.detail = res;
                    this.detail.SJPFFJ = this.dealFj(this.detail.SJPFFJ),
                        this.detail.WQJCBGFJ = this.dealFj(this.detail.WQJCBGFJ),
                        this.detail.JHSQWJFJ = this.dealFj(this.detail.JHSQWJFJ),
                        this.detail.CNHFJ = this.dealFj(this.detail.CNHFJ),
                        this.detail.XYFJ = this.dealFj(this.detail.XYFJ),
                    // if(!this.detail.JHPC){
                    //     this.detail.JHPC = this.jhpc
                    // }
                    // if(!this.detail.JHND){
                    //     this.detail.JHND = this.nowYear
                    // }
                    // if(!this.detail.SZS){
                    //     this.detail.SZS = this.bcity.szs
                    // }
                    // if(!this.detail.SZX){
                    //     if(this.bcity.szx != this.bcity.szs){
                    //         this.detail.SZX = this.bcity.szx
                    //     }
                    // }

                    console.log(this.detail);
                    // this.getItemByQQID([{ID:this.detail.QQID}])
                })
            },
            exportData(type) {
                var that = this
                // /api/Plan_filling/PlanFillingTemplateWrite
                // /api/Plan_filling/Export
                this.loading = true;
                if(type == 1){
                    this.http.post('/api/Plan_filling_bridge/PlanFillingBridgeTemplateWrite',this.expPostData(type)).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            '2023年黑龙江省普通国省干线公路危旧桥改造项目投资计划表'+timestamp+'.xls'
                        );
                    })
                }else if(type==2){
                    this.http.post('/api/Plan_filling_bridge/PlanFillingBridgeTemplateWrite',this.expPostData(type)).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling_bridge' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        var names = this.bcity.szx||this.bcity.szs;
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            '黑龙江省普通国省干线公路危旧桥改造项目投资计划表'+timestamp+'.xls'
                        );
                    })
                }else if(type==3){
                    let c = [];
                    this.expParamsCity.map(r=>{c.push(r[1])})
                    let city = Array.from(new Set(c));

                    var query_SZS = {
                        Name: "SZS",
                        Value: city.join(","),
                        DisplayType: "checkbox",
                    };
                    var query_flowState = {
                        Name: "FlowState",
                        Value: '4',
                        DisplayType: "Equal",
                    };
                    var query_where = [
                        query_SZS,
                        query_flowState
                    ];
                    var postData = {
                        page: this.currentPage,
                        rows: this.pageSize,
                        Sort: "XH",
                        Order: "asc",
                        wheres: JSON.stringify(query_where),
                        value:this.jhpc
                    };
                    this.http.post('/api/Plan_filling/PlanFillingTemplateWriteZIP',postData).then(res=>{
                        this.loading = false;
                        if(!res.status){
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/" + 'Plan_filling' + "/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            (this.nowYear+1)+'年'+this.jhpc+'国省道改造投资计划'+timestamp+'.zip'
                        );
                    })

                    // console.log(city);
                }else{
                    this.loading = false;
                }


                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                this.expParamsCity = [];
                var postData = { SZDS: "", SZX: "" };
                var newres = [];
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        // let citys = [
                        //     {
                        //         value: "",
                        //         label: "北大荒集团",
                        //         children:[
                        //             {
                        //                 value: "",
                        //                 label: "建三江管理局",
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         value: "",
                        //         label: "省建设中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省公路中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省交投集团",
                        //         children:[],
                        //     }
                        // ];
                        // res = res.concat(citys)
                        // console.log(res);
                        res.map((r) => {
                            r.value = r.label;
                            newres.push({
                                level: r.level, value: r.label, label:r.label
                            })
                            this.expParamsCity.push(['',r.value])
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;
                        this.regions2[0].children = newres;
                        // console.log(this.regions2);
                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_filling_bridge/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        this.getTJ(1);
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                        }
                    });
            },
            postDataDs() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    // Name: "BuildNature",
                    Name: "JSXZ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目类型
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_xmlx = {
                    Name: "XLXZ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };

                var xmlbArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlxXMLB.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmLB = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };

                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };
                var query_flowState = {
                    Name: "FlowState",
                    Value: '3',
                    DisplayType: "Equal",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        // query_xmLB,
                        query_szds,
                        query_Flow,
                        query_ssx,
                        query_xmmc,
                        query_xmlx,
                        query_jsgm,
                        query_type,
                        query_flowState
                    ]),
                };
                return postData;
            },
            postData() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_qlmc = {
                    Name: "QLMC",
                    Value: this.qlmc,
                    DisplayType: "like",
                };
                //时间
                var query_time = {
                    Name: "JHND",
                    Value: this.year,
                    DisplayType: 'Equal',
                };
                var query_KGN = {
                    Name: "KGN",
                    Value: this.KGN,
                    DisplayType: 'Equal',
                };

                //项目编码
                var query_qlbm = {
                    Name: "QLBM",
                    Value: this.qlbm,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsxz = {
                    // Name: "BuildNature",
                    Name: "JSXZ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //评定等级
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_pddj = {
                    Name: "PDDJ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };

                var xmlbArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlxXMLB.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmLB = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };

                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };
                var query_Year = {
                    Name: "JHND",
                    Value: this.PCyear[0],
                    DisplayType: "Equal",
                };
                var query_PC= {
                    Name: "JHPC",
                    Value: this.PCyear[1],
                    DisplayType: "Equal",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_KGN,
                        query_time,
                        query_pddj,
                        query_qlbm,
                        query_szds,
                        query_jsxz,
                        query_ssx,
                        query_qlmc,
                        query_Flow
                    ]),
                };
                return postData;
            },
            expPostData(type) {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_qlmc = {
                    Name: "QLMC",
                    Value: this.qlmc,
                    DisplayType: "like",
                };
                //项目编码
                var query_qlbm = {
                    Name: "QLBM",
                    Value: this.qlbm,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "SZS",
                    Value: szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx,
                    DisplayType: "like",
                };
                //建设性质
                var jsgmArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsxz = {
                    // Name: "BuildNature",
                    Name: "JSXZ",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
                //评定等级
                var xmlxArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlx.forEach((element) => {
                    xmlxArray.push(element);
                });
                var query_pddj = {
                    Name: "PDDJ",
                    Value: xmlxArray.join(","),
                    DisplayType: "checkbox",
                };

                var xmlbArray = [];
                // console.log(this.checkedXmlx);
                this.checkedXmlxXMLB.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmLB = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };

                var query_type = {
                    Name: "FillingType",
                    Value: '1',
                    DisplayType: "Equal",
                };
                var query_Flow = {
                    Name: "FlowState",
                    Value: this.checkedFlow.join(","),
                    DisplayType: "checkbox",
                };
                var query_Year = {
                    Name: "JHND",
                    Value: this.PCyear[0],
                    DisplayType: "Equal",
                };
                var query_PC= {
                    Name: "JHPC",
                    Value: this.PCyear[1],
                    DisplayType: "Equal",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_pddj,
                        query_qlbm,
                        query_szds,
                        query_jsxz,
                        query_ssx,
                        query_qlmc,
                        query_Flow
                    ]),
                };
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.gllx = "";
                this.checkedJsxz= [];
                this.checkedFlow= [];
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
                this.checkedXmlx = [];
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
            setNumDefault(nums){
                if(!nums) return 0;
                return parseFloat(nums);
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;

                // var num=money.trim();
                // var ss=num.toString();
                // if(ss.length==0){
                //     return 0.00;
                // }
                // num=new Number(ss.replace(/,/g, ""));
                // return parseFloat(num);
            },
        },
        // watch: {
        //     detail: {
        //         immediate: true,
        //         deep: true
        //     },
        //     activeIndex(v) {
        //         if (v) {
        //             this.tableHeight = document.body.offsetHeight - 450;
        //         } else {
        //             this.tableHeight = document.body.offsetHeight - 245;
        //         }
        //     },
        // },
    }
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .littleTable{
        /deep/input[readonly] {
            background-color: #fff !important;
            border: 0px solid #ddd !important;
        }
    }
    .editWrap{

        /deep/.el-drawer__body {
            padding: 12px 15px 0px;
            /* padding-bottom: 60px; */
        }

    }
    /deep/ .el-textarea{
        .el-textarea__inner{
            height: auto !important;
            min-height: auto !important;
        }
    }
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }

    .roadFront{
        .jhky{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        .ty{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
            }
            .tz{
                /deep/.el-input__inner{
                    width: 280px;
                }
                /deep/.el-form--inline .el-form-item{
                    margin-right: 0px;
                }
            }
            .jhky{
                /deep/.el-input__inner{
                    width: 135px;
                }
            }
            .ty{
                /deep/.el-input__inner{
                    width: 270px;
                }
            }
            .tztop{
                /deep/.el-form-item__label{
                    background: unset;
                }
                /deep/.el-input__inner {
                    width: 260px;
                }
            }
            .littleTable{
                /deep/.el-input__inner {
                    width: 270px;
                    border: none;
                    height: 45px;
                }
            }
            .littleTable2{
                /deep/.el-input__inner {
                    width: 142px;
                    /*margin: 2px 4px;*/
                    border: none;
                    height: 45px;
                }
            }
        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #f2f8fe;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 330px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error {
        position: relative !important;
        top: auto;
        display: block;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 4px;
        .dwmc{
            padding-left: 10px;
            text-align: left;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #ffffff;
            border-radius: 4px 0 0 4px;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #ffffff;
            border-radius: 0px 4px 4px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }

    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 47px;
        background: #F5F7FA;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 47px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .flexs-m {
        display: flex;
        align-items: initial;
    }
    .title {
        margin-top: 20px;
        background: #f2f8fe;
        display: flex;
        align-content: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        i {
            line-height: 32px;
            margin-right: 12px;
            font-size: 18px;
        }
        span {
            display: block;
            width: 98px;
            height: 32px;
            background: #409eff;
            line-height: 32px;
            font-size: 16px;
            font-family: Segoe UI;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
        }
    }

    .name {
        width: 220px;
        height: 42px;
        background: #fafafa;
        text-align: center;
        line-height: 42px;
        font-weight: 600;
    }
    .value {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        padding: 0 10px;
        // height: 42px;
        line-height: 42px;
        font-weight: 400;
        box-sizing: border-box;
        color: #666666;
    }
    .name1 {
        width: 74px;
        line-height: normal;
        label {
            width: 100%;
            padding: 0;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: Segoe UI;
            font-weight: 400;
            color: #ffffff;
            i {
                font-style: normal !important;
            }
        }
    }
    .fileWrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .value1 {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        display: flex;
        height: auto;
        label {
            display: inline-block;
            vertical-align: baseline;
        }
        .files {
            display: block;
            // border: 1px solid #b3d8ff;
            // height: 36px;
            // line-height: 36px;
            border-radius: 4px;
            position: relative;
            top: 2px;
            .wj {
                margin-bottom: 5px;
                border: 1px solid #b3d8ff;
                height: 30px;
                vertical-align: middle;
                line-height: 30px;
                display: inline-block;
                margin-left: 10px;
                border-radius: 4px;
                padding: 0 5px;
                position: relative;
                top: -2px;
            }
        }
    }
    .name3 {
        background: none;
    }
    .file{
        margin-right: 10px;
        display:flex;
        align-items: center;
        margin-top: 0px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 780px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .newform{
        /deep/.el-input__inner{
            width: 100% !important;
            padding: 0 5px !important;
        }
        /deep/.el-form-item__error{
            position: absolute !important;
            top: -2px;
        }
        /deep/.el-select{

            /deep/.el-input--suffix{
                width: 100% !important;
                /deep/.el-input__inner{
                    text-align: right;
                    // color: #C0C4CC;
                    font-size: 14px;
                    padding-right: 30px !important;

                }

            }
            /deep/.is-disabled{

                /deep/.el-input__inner{
                    background-color: #F5F7FA !important;

                }
            }
        }
    }
    .newre{
        display: inline-block;
        background: #409eff;
        padding: 0px 5px;
        border-radius: 50px;
        margin: 0 9px;
        position: relative;
        top: 1px;
        /deep/.el-input__suffix{
            right: 9px;
            top: -1px;
        }
        /deep/.el-tag{
            background: none !important;
        }
        /deep/.el-tag:not(:first-child){
            display: none;
        }
        /deep/.el-button--mini{
            position: relative;
            top: -1px;
            padding: 3px 0 !important;
        }
        /deep/.el-input__inner{
            width: 120px !important;
            height: 20px !important;
            border-radius: 50px;
        }
        /deep/.el-input--suffix{
            width: 100% !important;
            .el-input__icon{
                line-height: 30px;
            }
        }
    }
    .colors{
        /deep/.el-input__inner{
            color: #f00;
        }
    }
    /deep/input [type="number"]{
        -moz-appearance:textfield;
    }
    /deep/input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    /deep/input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    .inputcenter{
        /deep/.el-input__inner{
            text-align: center;
        }
    }
    .inputright{
        /deep/.el-input__inner{
            text-align: right;
        }
    }
    /deep/.is-disabled{
        .el-input__inner{
            background-color: #F5F7FA !important;
        }

    }
    .dis{
        background-color: #F5F7FA !important;
    }
    .xxh{
        display: inline-block;
        width: 26.5%;
        /deep/.el-input__inner{

            border: 0;
            border-bottom: 1px #dcdfe6 solid;
            width: 100% !important;
        }
    }
    .fale{
        padding:3px 5px;
        background: #F5F7FA;
        color: #c0c4cc;text-align: right
    }
</style>
