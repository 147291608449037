<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="demo-form-inline formBox">
                                    <el-form-item label="文件名称">
                                        <el-input style="width: 160px" size="medium" v-model="wjmc" placeholder="请输入文件名称" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="文号">
                                        <el-input style="width: 160px" size="medium" v-model="wh" placeholder="请输入文号" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="文件类型">
                                        <el-select  placeholder="请选择文件类型" v-model="lx" >
                                            <el-option label="普通文件" value="0"></el-option>
                                            <el-option label="财政指标文件" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:32px;margin-left: 10px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">重置</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <!--                                <span class="fright">-->
                                <!--                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>-->
                                <!--                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>-->
                                <!--                                </span>-->
                                <div style="display: flex;align-items: center;justify-content: space-between">
                                    <h3 class="tit">文件中心</h3>
                                    <el-button @click="addItem()" type="primary" size="mini" round icon="el-icon-upload2">新建</el-button>
                                </div>
                            </div>
                            <el-tabs v-model="activeRoad" @tab-click="changeRoad" style="padding-left: 20px">
                                <el-tab-pane label="2016年" name="2016"></el-tab-pane>
                                <el-tab-pane label="2017年" name="2017"></el-tab-pane>
                                <el-tab-pane label="2018年" name="2018"></el-tab-pane>
                                <el-tab-pane label="2019年" name="2019"></el-tab-pane>
                                <el-tab-pane label="2020年" name="2020"></el-tab-pane>
                                <el-tab-pane label="2021年" name="2021"></el-tab-pane>
                                <el-tab-pane label="2022年" name="2022"></el-tab-pane>
                                <el-tab-pane label="2023年" name="2023"></el-tab-pane>
                                <el-tab-pane label="2024年" name="2024"></el-tab-pane>
                                <el-tab-pane label="2025年" name="2025"></el-tab-pane>
                            </el-tabs>
                            <div class="box-content">
                                <el-table :highlight-current-row="true" :row-class-name="tableRowClassName" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="large " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column fixed prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column fixed prop="" align="center" :width="120" label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column fixed prop="WH" label="文号" align="left" show-overflow-tooltip :width="200" ></el-table-column>
                                    <el-table-column prop="WJMC" label="文件名称" align="left" show-overflow-tooltip></el-table-column>
                                    <el-table-column label="发文机关"  prop="FWJG" show-overflow-tooltip  align="center"></el-table-column>
                                    <el-table-column label="发文日期"  prop="FWRQ" show-overflow-tooltip   align="center"></el-table-column>
<!--                                    <el-table-column label="资金额度"  prop="ZJED" how-overflow-tooltip align="center"></el-table-column>-->
<!--                                    <el-table-column label="资金来源"  prop="ZJLY" how-overflow-tooltip  align="center"></el-table-column>-->
                                    <el-table-column width="150"  prop="name" label="附件" align="center">
                                        <template slot-scope="scope">
                                            <el-popover trigger="hover" placement="top" v-if="scope.row.FJ.length > 0">
                                                <ul class="fj-list">
                                                    <li v-for="(item,i) in scope.row.FJ" :key="i">
                                                        <a target="view_window" :href="baseUrl+item.url+item.name">
                                                            {{item.name}}
                                                        </a>
                                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                                    </li>
                                                </ul>

                                                <div slot="reference" class="name-wrapper">
                                                    <el-tag :type="scope.row.FJ.length>0?'success':'danger'" size="small">已上传{{scope.row.FJ.length}}个</el-tag>
                                                </div>
                                            </el-popover>
                                        </template>
                                    </el-table-column>

                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>

                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <el-dialog :wrapperClosable="false" :visible.sync="showAddModel" @close="closeDrawer" width="60%" :title="detail.WJMC"  class="editModal">
                <el-form :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm" :inline="true" class="demo-form-inline"  v-loading="uploadLoading">
                            <div class="col">
                                <div class="col-1">
                                    <el-row>
                                    <el-form-item label="文件名称" prop="WJMC"  :rules="[{ required: true, message:'请输入文件名称',trigger: 'blur' }]">
                                        <el-input  :disabled="editStatus" placeholder="文件名称" v-model="detail.WJMC" ></el-input>
                                    </el-form-item>
                                    <el-form-item label='文号'  prop="WH" :rules="[{ required: true, message:'请输入文号名称',trigger: 'blur' }]">
                                        <el-input :disabled="editStatus" placeholder="文号" v-model="detail.WH" ></el-input>
                                    </el-form-item>
                                    <el-form-item label="发文机关" prop="FWJG" :rules="[{ required: true, message:'请输入发文机关',trigger: 'blur' }]" >
                                        <el-input :disabled="editStatus" placeholder="单位名称" v-model="detail.FWJG" ></el-input>
                                    </el-form-item>
                                        <el-form-item label="年度" prop="ND" :rules="[{ required: true, message:'请选择年度',trigger: 'blur' }]" >
                                            <el-date-picker  style="width:100%"
                                                             format="yyyy"
                                                             :disabled="editStatus"
                                                             value-format="yyyy"
                                                             v-model="detail.ND" type="year" placeholder="选择年度" >
                                            </el-date-picker>
                                        </el-form-item>
                                    <el-form-item label="发文日期" prop="FWRQ" :rules="[{ required: true, message:'请选择发文日期',trigger: 'blur' }]">
                                        <el-date-picker  style="width:100%"
                                                         type="date"
                                                         format="yyyy-MM-dd"
                                                         :disabled="editStatus"
                                                         value-format="yyyy-MM-dd"
                                                         v-model="detail.FWRQ"  placeholder="选择发文日期" >
                                        </el-date-picker>
                                    </el-form-item>
                                        <el-form-item label="文件类型" prop="LX" :rules="[{ required: true, message:'请选择文件类型',trigger: 'change' }]" >
                                            <el-select :disabled="editStatus" placeholder="请选择文件类型" v-model="detail.LX" >
                                                <el-option label="普通文件" value="0"></el-option>
                                                <el-option label="财政指标文件" value="1"></el-option>
                                            </el-select>
                                        </el-form-item>
<!--                                    <el-form-item label="资金额度" prop="ZJED"  :rules="[{ required: true, message:'请输入资金额度',trigger: 'blur' }]">-->
<!--                                        <el-input :disabled="editStatus" placeholder="资金额度" v-model="detail.ZJED" ></el-input>-->
<!--                                    </el-form-item>-->
<!--                                    <el-form-item label="资金来源" prop="ZJLY" :rules="[{ required: true, message:'请输入资金来源',trigger: 'blur' }]">-->
<!--                                        <el-input :disabled="editStatus" placeholder="资金来源" v-model="detail.ZJLY" ></el-input>-->

<!--                                    </el-form-item>-->
                                    </el-row>
                                    <el-row style="display: flex">
                                        <el-form-item>
                                            <label class="fright"  >
                                                <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                <input  style="width: 72px" type="file" v-show="false" name="file" ref="file" :id="parseInt(Math.random()*10000)"   multiple="multiple"  @change="onSubmit($event)" />
                                            </label>
                                        </el-form-item>
                                        <div>
                                            <div class="file" v-for="(item,index) in detail.FJ" :key="index">
                                                <a :href="baseUrl+item.url+item.name" target="_blank">
                                                    <img src="../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                                    <!--                                </a>-->
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index)">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </el-row>
                                </div>
                            </div>
                </el-form>
                <div class="footer">
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="cancel">取消</el-button>
                </div>
            </el-dialog>

        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>


    </div>
</template>
<script>
    // import FlowXmk from "../../components/Query/Road/GhkFlow";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    export default {
        name: "investment",
        data() {
            return {
                editStatus:false,
                JHND:[],
                JHPC:'',
                wjmc:'',
                lx:'0',
                wh:'',
                activeRoad: '2016',
                activeName:'0',
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    FJ:[]
                },
                showDetail: false,
                showAddModel:false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                // baseUrl: "http://192.168.0.112:9991/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                showEditModel: false,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                currentPage: 1, //当前页
                pageSize: 30, //每页记录数
                tableData: [], //请求列表
                total: 0, //请求记录数
                totalSqu:0,
                searchAll:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                rowData:{},
            };
        },
        mounted() {
            var that = this
            // this.getYearsToNow()

            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 200;
                // if (localStorage.getItem('xmmc')){
                //     this.xmmc = localStorage.getItem('xmmc')
                //     this.search()
                // }
            }, 100);
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            this.http.post('/api/Plan_invest/GetPageTotal',{}).then(res=>{
                this.totalInfo = res
            })
        },
        computed:{

        },
        methods: {
            changeYear(val){
                console.log(val)
                var a = []
                val.map((item,index)=>{
                    a.push(item[0]+item[1])
                })
                this.JHND = a
            },
            getYearsToNow() {
                // this.yearsToNow=[2020,2021,2022]
                //获取到从那一年开始
                let smallYears = 2016
                //获取当前时间
                let date = new Date
                let nowYears = date.getFullYear()+1
                let Years = nowYears - smallYears
                let arrYear = []
                for (let i = 0; i <= Years; i++) {
                    arrYear.push(nowYears--)
                }
                arrYear.forEach(item => {
                    //下拉框的数组
                    this.yearsToNow.unshift({value:item,label: item,children:[
                            { value: '第一批', label: '第一批' },
                            { value: '第二批', label: '第二批' },
                            { value: '第三批', label: '第三批' },
                            { value: '大本计划', label: '大本计划'}
                        ]})
                })
                console.log(this.yearsToNow,'111')
                var pop = [{

                }]
            },
            toTb(val){
                console.log(val)
                val.ISXJ = true
                val.id='00000000-0000-0000-0000-000000000000'
                val.FillingType = '1'
                this.http.post('/api/Plan_filling/AddFilling',val).then(res=>{
                    this.buttonLoading = false;
                    if(res.status){
                        this.showAddModel = false
                        this.search()
                        this.$message.success(res.message)
                    }else{
                        this.$message.error(res.message)
                    }
                })
                // localStorage.setItem('fillInfo',JSON.stringify(val))
                // setTimeout(()=>{
                //     this.$router.push('/plainFilling/heighRoad')
                // },50)
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeRoad(tab, event){
                console.log(this.activeRoad)
                this.search()
            },
            chooseYear(val){
                console.log(val.name)
                if(val.label == '全部'){
                    this.gllx = ''
                }else{
                    this.gllx = val.label
                }
                // if(val.name == '全部'){
                //     this.jhnds = ''
                // }else{
                //     this.jhnds = val.name
                //
                // }
                this.search()
            },
            tableRowClassName({ row, rowIndex }) {
                if (row.ZJFX === '是' || row.ZDFX === '是') {
                    return 'warning-row'
                }
                return ''
            },
            changeZzfx(val){
                console.log(val)
                if(val === '是'){
                    this.zzfxyy = true
                }else{
                    this.detail.ZJFXYY = ''
                    this.zzfxyy = false
                }
            },

            handleClick(tab, event) {
                console.log(tab, event);
            },
            closeDrawer(){
                this.$refs.detailForm.resetFields()
                this.detail = {
                    FJ:[]
                }
                console.log('关闭了')
            },
            closeDrawer1(){
                this.currentPage1 = 1
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            //保存
            saveCompany(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        this.newAdd ?  this.add(this.editModel) : this.update(this.editModel)

                    } else {

                        return false;
                    }
                });
            },
            //新增的
            add(params){
                params.id = '00000000-0000-0000-0000-000000000000'
                this.http.post('/api/Plan_document_center/AddItem',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.detail = {FJ:[]}
                        this.showAddModel = false
                        this.search()
                        this.$refs.detailForm.resetFields()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //编辑的
            update(params){
                this.http.post('/api/Plan_document_center/UpdateItem',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.search()
                        this.detail = {FJ:[]}
                        this.showAddModel = false
                        this.$refs.detailForm.resetFields()
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            //删除
            deletedCompany(item){
                this.http.post('/api/Plan_buildcompany/DeleteBuildcompany',item).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                console.log(this.confirmSSW)
                if (this.chek.length != 0 ){
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }
                this.showSswxm = false
                this.sswform = {}
                this.currentPage1 = 1
            },
            //编辑
            edit(item){
                this.newAdd = false
                this.title = '修改建设单位'
                // this.editModel = JSON.parse(JSON.stringify(item))
                this.showEdit = true
            },

            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                console.log(this.newAdd)
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                console.log(this.newAdd)
                // if (this.newAdd == false){
                //
                //     return false
                // }
                console.log(value)
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            chooseXm(row){
                var that = this
                this.sswform = {}
                this.showSswxm = true
                this.getSWWXMList()
                console.log(this.confirmSSW)
                if( this.confirmSSW.length == 0){
                    this.$nextTick(()=>{
                        this.$refs.multipleTable.clearSelection()
                    })
                }else{
                    setTimeout(()=>{
                        this.$nextTick(()=>{
                            that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                    if (item.XMMC == that.confirmSSW[0].XMMC){
                                        return item
                                    }
                                }),
                                true);
                        })
                    },0)

                    // that.$refs.multipleTable.toggleRowSelection(that.chek[0],true);

                }
            },
            selectCompany(val){
                console.log(val[0])
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;
                }
            },
            getSWWXMList(){
                var that = this
                console.log(that.confirmSSW)
                this.http.post('/api/Plan_high_national_early/GetData', this.postData2()).then(res=> {
                    this.sswxmList = res.rows
                    this.total1 = res.total
                    this.$nextTick(()=>{
                        if(that.confirmSSW.length != 0){

                            that.$refs.multipleTable.toggleRowSelection(that.sswxmList.find((item) => {
                                    if (item.XMMC == that.confirmSSW[0].XMMC){
                                        return item
                                    }
                                }),
                                true);
                        }
                    })
                })
            },
            openTable(row){
                this.assign = true
                this.currentPage1 = 1
                this.oldYearId = row.ID
                this.getCompanyList()
            },
            getCompanyList(){
                var that = this
                this.http.post('/api/View_Plan_road_investment_old_year/GetPageData',this.postData1()).then(res=> {
                    this.companyData = res.rows
                    this.companyData.map((item,index)=>{
                        var a = []
                        if(item.FJ != '' && item.FJ != null){
                            item.FJ = item.FJ.split(';')
                            item.FJ.map((subItem)=>{
                                subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                a.push(subItem)
                            })
                            item.FJ = a
                        }else {
                            item.FJ = []
                        }

                    })
                })
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.$refs.detailForm.resetFields()
                this.showAddModel = false
                this.editStatus = false
            },
            //删除图片
            deleted(index){
                this.detail.FJ.splice(index,1)
            },
            activeChange(e){
            },
            changeTotal(val,type){
                var that = this
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                if (val && !reg.test(val)) {
                    return false
                }
                if (type == 'nyd'){
                    if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                        this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                    }else{
                        this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                    }
                }else{
                    if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                        this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                    }else{
                        this.totalSqu = val == '' ? 0: parseFloat(val)
                    }
                }

            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            dealFj(data){
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }

                // if (data != null || data != undefined){
                //  return  {url:data.substring(0,data.lastIndexOf("/") + 1),name:data.substring(data.lastIndexOf("/") + 1,data.length)}
                // }else{
                //     return []
                // }
            },
            dealSaveFj(data){
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                console.log( pfwjString.join(';'))
                return pfwjString.join(';')
            },
            addItem(){
                this.editStatus = false
                this.showAddModel = true
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        console.log(params)
                        if(params.FJ){
                            params.FJ = this.dealSaveFj(params.FJ)
                        }
                        console.log(params)
                        params.Id ? this.update(params) : this.add(params)
                    } else {
                        return false;
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                console.log(file)
                let form = new FormData();
                let arrs = [];
                console.log(file);
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_document_center/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            console.log( this.detail.FJ)
                            // this.details[type].push(res.data + v);
                            this.detail.FJ.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });
                this.$refs.file.value = null;//vue中
            },
            changeCollapse(val){
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;

                // var num=money.trim();
                // var ss=num.toString();
                // if(ss.length==0){
                //     return 0.00;
                // }
                // num=new Number(ss.replace(/,/g, ""));
                // return parseFloat(num);
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {
                    FJ:[]
                }

            },
             openDetailsEdit(row) {
                var that = this
                 that.showAddModel = true
                 // this.editStatus = true
                 this.http
                    .post(
                        "/api/Plan_document_center/GetItemById?id="+row.Id,
                        {},
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                    console.log(res)
                    this.detail = res
                    this.detail.FJ = this.dealFj(this.detail.FJ)
                    });

            },
            exportData() {
                var that = this
                this.http.post("/api/Plan_filling_history/PlanFillingHistoryTemplateWrite",this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" +'Plan_filling_history'+ "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            //查询
            search() {
                console.log('qingqiuzomg')
                this.http
                    .post(
                        "/api/Plan_document_center/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                            this.tableData.map((item,index)=>{
                                var a = []
                                if(item.FJ != '' && item.FJ != null){
                                    item.FJ = item.FJ.split(';')
                                    item.FJ.map((subItem)=>{
                                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                                        a.push(subItem)
                                    })
                                    item.FJ = a
                                }else {
                                    item.FJ = []
                                }

                            })
                        }
                    });
            },
            postData() {
                //计划年度
                var query_nd = {
                    Name: "ND",
                    Value: this.activeRoad,
                    DisplayType: "Equal",
                };
                //计划批次
                var query_wjmc = {
                    Name: "WJMC",
                    Value: this.wjmc,
                    DisplayType: "like",
                };
                var query_lx = {
                    Name: "LX",
                    Value: this.lx,
                    DisplayType: "Equal",
                };
                //项目名称
                var query_wh = {
                    Name: "WH",
                    Value: this.wh,
                    DisplayType: "like",
                };


                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_lx,
                        query_nd,
                        query_wh,
                        query_wjmc
                    ]),
                };
                return postData;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            clearCondition() {
                this.wjmc = "";
                this.wh = "";

            },
            handleChange(value) {
                this.checkedXmxz = [];
                this.search();
            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
    .warning-row {
        background:#fef9f9 !important;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /*/deep/.el-collapse-item__content {*/
        /*    padding:10px 15px 20px 15px  !important;*/
        /*}*/
        .editModal{
            /deep/.el-input__inner{
                height: 35px;
                line-height: 35px;
                width: 220px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
            }

        }

        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
            /deep/.el-form-item__label{
                width: 90px;
                /*background: #F2F8FE;*/
                /*color: #78818E;*/
                line-height: 30px;
                float: unset;
            }

        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }


    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 178px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }
    /deep/.el-form--inline .el-form-item{
        margin-right: 0;
    }
    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }

    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 5px;
        .dwmc{
            padding-left: 15px;
            text-align: left;
            width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 35px;
            background: #ffffff;
            border-radius: 5px 0 0 5px;
            line-height: 35px;

        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 35px;
            background: #fafafa;
            border-radius: 0 5px 5px 0;
            i{
                font-size: 16px;
            }
        }
    }

    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 42px;
        background: #f5f7fa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 185px;
        height: 42px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 42px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .w20{
        width: 220px;
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 0;
            background: #F2F8FE;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }

    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .newform{
        /deep/.el-input__inner{
            width: 100% !important;
            padding: 0 5px !important;
        }
        /deep/.el-form-item__error{
            position: absolute !important;
            top: -2px;
        }
        /deep/.el-select{

            /deep/.el-input--suffix{
                width: 100% !important;
                /deep/.el-input__inner{
                    text-align: right;
                    // color: #C0C4CC;
                    font-size: 14px;
                    padding-right: 30px !important;

                }

            }
            /deep/.is-disabled{

                /deep/.el-input__inner{
                    background-color: #F5F7FA !important;

                }
            }
        }
    }
    .newre{
        display: inline-block;
        background: #409eff;
        padding: 0px 5px;
        border-radius: 50px;
        margin: 0 9px;
        position: relative;
        top: 1px;
        /deep/.el-input__suffix{
            right: 9px;
            top: -1px;
        }
        /deep/.el-tag{
            background: none !important;
        }
        /deep/.el-tag:not(:first-child){
            display: none;
        }
        /deep/.el-button--mini{
            position: relative;
            top: -1px;
            padding: 3px 0 !important;
        }
        /deep/.el-input__inner{
            width: 120px !important;
            height: 20px !important;
            border-radius: 50px;
        }
        /deep/.el-input--suffix{
            width: 100% !important;
            .el-input__icon{
                line-height: 30px;
            }
        }
    }
    .colors{
        /deep/.el-input__inner{
            color: #f00;
        }
    }
    /deep/input [type="number"]{
        -moz-appearance:textfield;
    }
    /deep/input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    /deep/input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    .inputcenter{
        /deep/.el-input__inner{
            text-align: center;
        }
    }
    .inputright{
        /deep/.el-input__inner{
            text-align: right;
        }
    }
    /deep/.is-disabled{
        .el-input__inner{
            background-color: #F5F7FA !important;
        }

    }
    .dis{
        background-color: #F5F7FA !important;
    }
    .xxh{
        display: inline-block;
        width: 26.5%;
        /deep/.el-input__inner{

            border: 0;
            border-bottom: 1px #dcdfe6 solid;
            width: 100% !important;
        }
    }
   /deep/ .el-table__fixed, .el-table__fixed-right{
        height: 100% !important; //设置高优先，以覆盖内联样式
    }
</style>
